<template>
  <div class="c-mobile-filter" @click.self="onClose">
    <div class="c-mobile-filter__wrapper">
      <FHButton class="c-mobile-filter__close" @click.native="onClose">
        <CloseIcon />
      </FHButton>
      <slot />
    </div>
    <div class="c-mobile-filter__footer">
      <FHButton class="c-mobile-filter__apply" @click.native="onApplyFilters">
        Apply filters
      </FHButton>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount } from '@vue/composition-api'
import FHButton from '@forlagshuset/v-button'
import CloseIcon from '@/assets/svg/Icon_Close'

export default {
  components: {
    FHButton,
    CloseIcon,
  },

  setup(props, { emit }) {
    const onClose = () => {
      emit('close')
    }
    const onApplyFilters = () => {
      emit('apply')
    }
    const onEsc = ev => {
      if(ev.key === 'Escape') {
        emit('close')
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', onEsc)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onEsc)
    })

    return {
      onApplyFilters,
      onClose
    }
  },
}
</script>

<style lang="scss">
.c-mobile-filter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba($color-divider, 0.6);

  @include bp(large) {
    display: none;
  }
}

.c-mobile-filter__wrapper {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 5.25rem;
  overflow: auto;
  background: $color-white;
  padding: 1.25rem;
}

.c-mobile-filter__close {
  @include btnReset();

  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}

.c-mobile-filter__footer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5.25rem;
  background: $color-white;
  border: 1px solid $color-divider;
  padding: 0 1rem;
}

.c-mobile-filter__apply {
  @include btnReset();

  font-size: 0.875rem;
  color: $color-black;
  padding: 0.875rem 3.125rem;
  border: 1px solid $color-divider;
  background: $searchbox-bg;
}
</style>