<template>
  <div class="c-filters">
    <VHeading header="h3" class="o-heading c-filters__heading">
      {{ $t('FILTERS_HEADING') }}
    </VHeading>
    <FHButton
      defaultStyles
      class="c-filters__clear-btn"
      :disabled="!selectedTags.length && !selectedThemes.length"
      @click.native="onFiltersClear()"
    >
      {{ $t('FILTERS_CLEAR_ALL') }}
    </FHButton>
    <VHeading header="h4" class="u-mx o-heading c-filters__heading">
      {{ $t('FILTERS_HEADING_THEMES') }}
    </VHeading>
    <FiltersInput
      v-for="item in themes"
      :key="item.slug"
      :selected="isThemeSelected(item)"
      @filter-selected="onThemeSelected(item)"
      class="u-mx"
    >
      {{ item.name }}
    </FiltersInput>
    <div v-if="showLessonPlansFilters">
      <VHeading header="h4" class="u-mx o-heading c-filters__heading">
        {{ $t('FILTERS_HEADING_LESSON_PLANS') }}
      </VHeading>
      <FiltersInput
        v-for="item in outerFilters"
        :key="item.label"
        :selected="isSelected(item.value)"
        @filter-selected="onFilterSelected(item.value)"
        class="u-mx"
      >
        {{ item.label }}
      </FiltersInput>
      <FiltersDropdown
        v-for="(tag, name, index) in tags"
        :key="`${name}-${index}`"
        :name="name"
      >
        <FiltersInput
          v-for="(label, i) in tag"
          :key="`${label}-${i}`"
          :selected="isSelected(label.value)"
          @filter-selected="onFilterSelected(label.value)"
        >
          {{ label.label }}
        </FiltersInput>
      </FiltersDropdown>
      <FiltersDropdown :name="$t('LESSON_COMPETENCY_AIMS')">
        <FiltersInput
          v-for="aim in aims"
          :key="aim.id"
          :selected="isSelected(aim.id)"
          @filter-selected="onFilterSelected(aim.id)"
        >
          {{ aim.name }}
        </FiltersInput>
      </FiltersDropdown>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { fetchSH } from '@/api'
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import FiltersInput from './FiltersInput'
import FiltersDropdown from './FiltersDropdown'

const outerFilters = [
  {
    label: 'Deep learning',
    value: '5f057dfdb7f15c0011b8a003',
  },
  {
    label: 'Multilingualism',
    value: '5f057981b7f15c0011b89fab',
  },
]

export default {
  name: 'Filters',

  components: {
    FiltersInput,
    FiltersDropdown,
    FHButton,
    VHeading,
  },

  methods: {
    onFilterSelected(tagID) {
      const index = this.selectedTags.indexOf(tagID)

      if (index === -1) {
        this.selectedTags.push(tagID)
      } else {
        this.selectedTags.splice(index, 1)
      }

      if (this.onMobile) {
        this.$emit('selected-tag-mobile', {
          selectedTagsMobile: this.selectedTags,
        })
      } else {
        this.$emit('selected-tag', {
          selectedTags: this.selectedTags,
        })
      }
    },

    onFiltersClear() {
      this.selectedTags = []
      this.selectedThemes = []
      this.$emit('selected-tag', {
        selectedTags: this.selectedTags,
      })
      this.$router.push({
        query: { type: this.$router.history.current.query.type },
      })
    },

    isSelected(tagID) {
      return this.selectedTags.indexOf(tagID) !== -1
    },

    onThemeSelected(theme) {
      if (this.selectedThemes.some(t => t.slug === theme.slug)) {
        this.selectedThemes = this.selectedThemes.filter(
          t => t.slug !== theme.slug,
        )
      } else {
        this.selectedThemes.push(theme)
      }

      if (!this.onMobile) {
        //on Desktop router updates automatically
        this.$router.push({
          query: {
            unit: this.selectedThemes.map(t => t.slug),
            type: this.$router.history.current.query.type,
          },
        })
      } else {
        //on Mobile router is updated after apply filters button is clicked
        this.$emit('selected-themes', {
          selectedThemes: this.selectedThemes,
        })
      }
    },

    isThemeSelected(theme) {
      return this.selectedThemes.some(t => t.slug === theme.slug)
    },
  },

  props: {
    onMobile: {
      type: Boolean,
      default: false,
    },
    selectedTags: {
      type: Array,
      default: () => []
    }
  },

  setup(props, ctx) {
    const aims = ref([])
    const themes = computed(() =>
      ctx.root.$store.getters['content/getCourseUnits'].filter(
        u => !u.teacherUnit,
      ),
    )
    const unitFromURL = computed(() =>
      Array.isArray(ctx.root.$options.router.history.current.query.unit)
        ? ctx.root.$options.router.history.current.query.unit
        : [ctx.root.$options.router.history.current.query.unit],
    )
    const showLessonPlansFilters = computed(
      () =>
        ctx.root.$options.router.history.current.query.type === 'lesson-plans',
    )
    const selectedThemes = ref([])
    const tags = {
      Year: [
        { label: '8', value: '5f0ed346ea9c5c0011148429' },
        { label: '9', value: '5f0ed36cf8e2d6001252c206' },
        { label: '10', value: '5f0ed38ac1a6d300122f1d56' },
      ],
      'Basic skills': [
        { label: 'oral skills', value: '5f057e71b7f15c0011b8a006' },
        { label: 'writing', value: '5f4f95b92f2c4700180d49c2' },
        { label: 'reading', value: '5f057d86b7f15c0011b89ffe' },
        { label: 'digital skills', value: '5f057d7eb7f15c0011b89ffd' },
      ],
      'Interdisciplinary themes': [
        { label: 'health and life skills', value: '5f057d6db7f15c0011b89ffb' },
        {
          label: 'democracy and citizenship',
          value: '5f057d77b7f15c0011b89ffc',
        },
      ],
    }

    watch(unitFromURL, val => {
      selectedThemes.value = themes.value.filter(t =>
        val.some(ufu => ufu === t.slug),
      )  
    })

    onMounted(async () => {
      const caims = await fetchSH('competency-types/')
      const caimsRename = caims.map(aim => {
        const splitName = aim.description.split(/^(\d+)/)

        aim.order = splitName[1]

        return aim
      })

      aims.value = caimsRename.sort(
        (a, b) => parseInt(a.order, 10) > parseInt(b.order, 10),
      )
      selectedThemes.value = themes.value.filter(t =>
        unitFromURL.value.some(ufu => ufu === t.slug),
      )
    })

    return {
      aims,
      outerFilters,
      themes,
      showLessonPlansFilters,
      selectedThemes,
      tags,
    }
  },
}
</script>

<style lang="scss">
.c-filters__heading {
  h3 {
    margin-top: 0;
  }
}
</style>
