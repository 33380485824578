var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("c-search c-search--" + _vm.unitSlug + " u-mb")},[(_vm.crumbs)?_c('FHBreadcrumbs',{staticClass:"c-search__breadcrumbs",attrs:{"crumbs":_vm.crumbs}}):_vm._e(),_c('div',{staticClass:"c-search__wrapper"},[_c('div',{staticClass:"c-search__filters"},[(_vm.isTeacher)?_c('Filters',{attrs:{"tags":_vm.tags,"selected-tags":[].concat( _vm.selectedTags )},on:{"selected-tag":_vm.onSelectedTagUpdate}}):_c('nav',{staticClass:"c-search__unit-nav"},[_c('h3',{staticClass:"c-search__unit-nav-title"},[_vm._v(" "+_vm._s(_vm.$t('SEARCH_SIDE_NAV_TITLE'))+" ")]),_c('ul',{staticClass:"c-search__unit-nav-side"},_vm._l((_vm.units),function(unit){return _c('li',{key:unit.slug,staticClass:"c-search__unit-nav-item",class:[
              ("unit-nav-item--" + (unit.slug)),
              { active: _vm.unitSlug.some(function (us) { return unit.slug === us; }) } ],on:{"click":function($event){return _vm.$router.push(("/search?unit=" + (unit.slug) + "&type=text"))}}},[_vm._v(" "+_vm._s(unit.name)+" ")])}),0)])],1),_c('div',{staticClass:"c-search__tabs"},[_c('div',{staticClass:"c-search__unit-wrapper"},[(!_vm.isTeacher)?_c('UnitHeader',{staticClass:"c-search__unit-header",attrs:{"unitSlug":_vm.unitSlug[0],"type":"text","showCrumbs":false,"showCover":true,"showIndex":false}}):_vm._e(),(_vm.description)?_c('div',{directives:[{name:"md-html",rawName:"v-md-html",value:(_vm.description),expression:"description"}],staticClass:"o-description c-search__unit-description"}):_vm._e()],1),_c('FHTabs',{ref:"tabs",on:{"changed":_vm.onChangeTab}},[_c('FHTab',{attrs:{"name":_vm.$t('TEXT')}},[_c('div',{staticClass:"c-search__btn-wrapper"},[(_vm.isTeacher)?_c('FHButton',{staticClass:"c-search__btn-lp-filter",nativeOn:{"click":function($event){return _vm.onMobileFilterBtn($event)}}},[_c('FilterIcon'),_vm._v(" Filter")],1):_vm._e()],1),_vm._l((_vm.text),function(item,i){return _c('SearchTile',{key:i,attrs:{"to":("/text/" + (item.parentUnit) + "/" + (item.slug)),"type":item.name,"image":item.cover &&
                item.cover.image_type &&
                item.cover.image_type.file &&
                item.cover.image_type.file.url,"description":item.description,"tags":item.tags}})}),(!_vm.text.length && _vm.loading)?_c('div',{staticClass:"c-search__empty"},[_vm._v(" "+_vm._s(_vm.$t('NO_RESULTS'))+" ")]):_vm._e()],2),_c('FHTab',{attrs:{"name":_vm.$t('VIDEO')}},[_c('div',{staticClass:"c-search__btn-wrapper"},[(_vm.isTeacher)?_c('FHButton',{staticClass:"c-search__btn-lp-filter",nativeOn:{"click":function($event){return _vm.onMobileFilterBtn($event)}}},[_c('FilterIcon'),_vm._v(" Filter")],1):_vm._e()],1),_vm._l((_vm.video),function(item,i){return _c('SearchTile',{key:i,attrs:{"to":("/video/" + (item.parentUnit) + "/" + (item.slug)),"type":item.name,"image":item.cover &&
                item.cover.image_type &&
                item.cover.image_type.file &&
                item.cover.image_type.file.url,"description":item.description ? item.description : '',"tags":item.tags}})}),(!_vm.video.length && _vm.loading)?_c('div',{staticClass:"c-search__empty"},[_vm._v(" "+_vm._s(_vm.$t('NO_RESULTS'))+" ")]):_vm._e()],2),_c('FHTab',{attrs:{"name":_vm.$t('AUDIO_BOOK')}},[_c('div',{staticClass:"c-search__btn-wrapper"},[(_vm.isTeacher)?_c('FHButton',{staticClass:"c-search__btn-lp-filter",nativeOn:{"click":function($event){return _vm.onMobileFilterBtn($event)}}},[_c('FilterIcon'),_vm._v(" Filter")],1):_vm._e()],1),_vm._l((_vm.audiobook),function(item,i){return _c('SearchTile',{key:i,attrs:{"to":("/audio/" + (item.parentUnit) + "/" + (item.slug)),"type":item.name,"image":item.cover && item.cover.url,"description":"","tags":item.tags}})}),(!_vm.audiobook.length && _vm.loading)?_c('div',{staticClass:"c-search__empty"},[_vm._v(" "+_vm._s(_vm.$t('NO_RESULTS'))+" ")]):_vm._e()],2),(_vm.isTeacher)?_c('FHTab',{attrs:{"name":_vm.$t('LESSON_PLANS')}},[_c('div',{staticClass:"c-search__btn-wrapper"},[_c('FHButton',{staticClass:"c-search__btn-lp-filter",nativeOn:{"click":function($event){return _vm.onMobileFilterBtn($event)}}},[_c('FilterIcon'),_vm._v(" Filter")],1)],1),_vm._l((_vm.filteredLessonPlans),function(item,i){return _c('SearchTile',{key:i,attrs:{"to":("/lesson/" + (item.slug)),"type":item.name,"description":item.description ? item.description : '',"tags":item.tags}})}),(!_vm.filteredLessonPlans.length && _vm.loading)?_c('div',{staticClass:"c-search__empty"},[_vm._v(" "+_vm._s(_vm.$t('NO_RESULTS'))+" ")]):_vm._e()],2):_vm._e()],1)],1)]),(_vm.isTeacher && _vm.isMobileFilterOpen)?_c('MobileFilter',{on:{"close":_vm.onMobileFilterClose,"apply":_vm.onFiltersApply}},[_c('Filters',{attrs:{"tags":_vm.tags,"selected-tags":[].concat( _vm.selectedTagsMobile ),"on-mobile":""},on:{"selected-tag-mobile":_vm.onSelectedTagMobileUpdate,"selected-themes":_vm.onSelectedThemesUpdate}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }