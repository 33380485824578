<template>
  <div :class="`c-search c-search--${unitSlug} u-mb`">
    <FHBreadcrumbs
      class="c-search__breadcrumbs"
      v-if="crumbs"
      :crumbs="crumbs"
    />

    <div class="c-search__wrapper">
      <div class="c-search__filters">
        <Filters
          v-if="isTeacher"
          :tags="tags"
          :selected-tags="[...selectedTags]"
          @selected-tag="onSelectedTagUpdate"
        />
        <nav class="c-search__unit-nav" v-else>
          <h3 class="c-search__unit-nav-title">
            {{ $t('SEARCH_SIDE_NAV_TITLE') }}
          </h3>
          <ul class="c-search__unit-nav-side">
            <li
              class="c-search__unit-nav-item"
              :class="[
                `unit-nav-item--${unit.slug}`,
                { active: unitSlug.some(us => unit.slug === us) },
              ]"
              v-for="unit in units"
              :key="unit.slug"
              @click="$router.push(`/search?unit=${unit.slug}&type=text`)"
            >
              {{ unit.name }}
            </li>
          </ul>
        </nav>
      </div>
      <div class="c-search__tabs">
        <div class="c-search__unit-wrapper">
          <UnitHeader
            v-if="!isTeacher"
            class="c-search__unit-header"
            :unitSlug="unitSlug[0]"
            type="text"
            :showCrumbs="false"
            :showCover="true"
            :showIndex="false"
          />
          <div
            v-if="description"
            class="o-description c-search__unit-description"
            v-md-html="description"
          />
        </div>
        <FHTabs ref="tabs" @changed="onChangeTab">
          <FHTab :name="$t('TEXT')">
            <div class="c-search__btn-wrapper">
              <FHButton
                v-if="isTeacher"
                class="c-search__btn-lp-filter"
                @click.native="onMobileFilterBtn"
                ><FilterIcon /> Filter</FHButton
              >
            </div>
            <SearchTile
              v-for="(item, i) in text"
              :key="i"
              :to="`/text/${item.parentUnit}/${item.slug}`"
              :type="item.name"
              :image="
                item.cover &&
                  item.cover.image_type &&
                  item.cover.image_type.file &&
                  item.cover.image_type.file.url
              "
              :description="item.description"
              :tags="item.tags"
            />
            <div v-if="!text.length && loading" class="c-search__empty">
              {{ $t('NO_RESULTS') }}
            </div>
          </FHTab>
          <FHTab :name="$t('VIDEO')">
            <div class="c-search__btn-wrapper">
              <FHButton
                v-if="isTeacher"
                class="c-search__btn-lp-filter"
                @click.native="onMobileFilterBtn"
                ><FilterIcon /> Filter</FHButton
              >
            </div>
            <SearchTile
              v-for="(item, i) in video"
              :key="i"
              :to="`/video/${item.parentUnit}/${item.slug}`"
              :type="item.name"
              :image="
                item.cover &&
                  item.cover.image_type &&
                  item.cover.image_type.file &&
                  item.cover.image_type.file.url
              "
              :description="item.description ? item.description : ''"
              :tags="item.tags"
            />
            <div v-if="!video.length && loading" class="c-search__empty">
              {{ $t('NO_RESULTS') }}
            </div>
          </FHTab>
          <FHTab :name="$t('AUDIO_BOOK')">
            <div class="c-search__btn-wrapper">
              <FHButton
                v-if="isTeacher"
                class="c-search__btn-lp-filter"
                @click.native="onMobileFilterBtn"
                ><FilterIcon /> Filter</FHButton
              >
            </div>
            <SearchTile
              v-for="(item, i) in audiobook"
              :key="i"
              :to="`/audio/${item.parentUnit}/${item.slug}`"
              :type="item.name"
              :image="item.cover && item.cover.url"
              description=""
              :tags="item.tags"
            />
            <div v-if="!audiobook.length && loading" class="c-search__empty">
              {{ $t('NO_RESULTS') }}
            </div>
          </FHTab>
          <FHTab v-if="isTeacher" :name="$t('LESSON_PLANS')">
            <div class="c-search__btn-wrapper">
              <FHButton
                class="c-search__btn-lp-filter"
                @click.native="onMobileFilterBtn"
                ><FilterIcon /> Filter</FHButton
              >
            </div>
            <SearchTile
              v-for="(item, i) in filteredLessonPlans"
              :key="i"
              :to="`/lesson/${item.slug}`"
              :type="item.name"
              :description="item.description ? item.description : ''"
              :tags="item.tags"
            />
            <div
              v-if="!filteredLessonPlans.length && loading"
              class="c-search__empty"
            >
              {{ $t('NO_RESULTS') }}
            </div>
          </FHTab>
        </FHTabs>
      </div>
    </div>
    <MobileFilter
      v-if="isTeacher && isMobileFilterOpen"
      @close="onMobileFilterClose"
      @apply="onFiltersApply"
    >
      <Filters
        :tags="tags"
        :selected-tags="[...selectedTagsMobile]"
        on-mobile
        @selected-tag-mobile="onSelectedTagMobileUpdate"
        @selected-themes="onSelectedThemesUpdate"
      />
    </MobileFilter>
  </div>
</template>

<script>
import FHTabs from '@forlagshuset/v-tabs/FHTabs'
import FHTab from '@forlagshuset/v-tabs/FHTab'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import FHButton from '@forlagshuset/v-button'
import { ref, computed, onMounted } from '@vue/composition-api'
import SearchTile from '@/components/SearchTile'
import Filters from '@/components/Filters'
import UnitHeader from '@/components/UnitHeader'
import MobileFilter from '@/components/MobileFilter'
import FilterIcon from '@/assets/svg/Icon_Filter'

export default {
  name: 'Search',

  components: {
    FHTabs,
    FHTab,
    Filters,
    SearchTile,
    FHBreadcrumbs,
    UnitHeader,
    FHButton,
    MobileFilter,
    FilterIcon,
  },

  setup(props, ctx) {
    const appSlug = process.env.VUE_APP_APPSLUG
    const unitSlug = computed(() =>
      Array.isArray(ctx.root.$router.history.current.query.unit)
        ? ctx.root.$router.history.current.query.unit
        : (ctx.root.$router.history.current.query.unit && [
            ctx.root.$router.history.current.query.unit,
          ]) ||
          [],
    )
    const units = ref([])
    const selectedThemes = ref([])
    const title = ref('')
    const description = ref('')
    const tags = ref([])
    const text = ref([])
    const video = ref([])
    const audiobook = ref([])
    const lessonPlans = ref([])
    const onChangeTab = ref(() => {})
    const isTeacher = computed(
      () => ctx.root.$store.getters['appConfig/isTeacher'],
    )
    const loading = ref(false)
    const selectedTags = ref([])
    const selectedTagsMobile = ref([])
    const crumbs = ref([])
    const isMobileFilterOpen = ref(false)
    const onMobileFilterBtn = () => {
      isMobileFilterOpen.value = true
    }
    const onMobileFilterClose = () => {
      isMobileFilterOpen.value = false
      //reset tags
      selectedTagsMobile.value = selectedTags.value
    }

    const onFiltersApply = () => {
      isMobileFilterOpen.value = false
      selectedTags.value = selectedTagsMobile.value
      if (selectedThemes.value.length) {
        ctx.root.$options.router.push({
          query: {
            unit: selectedThemes.value.map(t => t.slug),
            type: ctx.root.$options.router.history.current.query.type,
          },
        })
      }
    }

    const filteredLessonPlans = computed(() => {
      if (selectedTags.value.length) {
        const currentLpArray = lessonPlans.value

        return currentLpArray.filter(cla => {
          let countTags = 0
          selectedTags.value.forEach(st => {
            if (
              cla.tags.some(tag => tag.id === st) ||
              cla.competencies.some(comp => comp.id === st) ||
              cla.keywords.some(keyword => keyword.id === st)
            ) {
              countTags = countTags + 1
            }
          })

          return countTags === selectedTags.value.length
        })
      } else {
        return lessonPlans.value
      }
    })

    if (ctx.root.$options.router.history.current.query.type) {
      ctx.root.$nextTick(() => {
        ctx.refs.tabs.setActive(
          `#${ctx.root.$options.router.history.current.query.type}`,
        )
        onChangeTab.value = selectedTab => {
          ctx.root.$router.push({
            query: { unit: unitSlug.value, type: selectedTab.tab.$el.id },
          })
        }
      })
    } else {
      onChangeTab.value = selectedTab => {
        ctx.root.$router.push({
          query: { unit: unitSlug.value, type: selectedTab.tab.$el.id },
        })
      }
    }

    onMounted(async () => {
      loading.value = false

      await ctx.root.$store.dispatch('content/setCourseUnits', appSlug)
      units.value = ctx.root.$store.getters['content/getCourseUnits'].filter(
        unit => !unit.teacherUnit,
      )

      if (!unitSlug.value.length) {
        await Promise.all(
          units.value.map(async u => {
            await ctx.root.$store.dispatch('content/setNewTag', {
              newTagId: u.tags[0].id,
              slug: u.slug,
            })
            await ctx.root.$store.dispatch('content/setLessonPlans', u.slug)
          }),
        )
        text.value = ctx.root.$store.getters['content/getAllText']
        audiobook.value = ctx.root.$store.getters['content/getAllAudiobook']
        video.value = ctx.root.$store.getters['content/getAllVideo']
        lessonPlans.value = ctx.root.$store.getters['content/getAllLessonPlans']
      } else {
        const filteredUnits = units.value.filter(u =>
          unitSlug.value.some(us => us === u.slug),
        )
        if (unitSlug.value === 1 && !isTeacher.value) {
          title.value = filteredUnits[0].name
          description.value = filteredUnits[0].value.description
        }
        await Promise.all(
          filteredUnits.map(async fu => {
            await ctx.root.$store.dispatch('content/setNewTag', {
              newTagId: fu.tags[0].id,
              slug: fu.slug,
            })
            text.value = [
              ...text.value,
              ...ctx.root.$store.getters['content/getText'](fu.slug),
            ]
            audiobook.value = [
              ...audiobook.value,
              ...ctx.root.$store.getters['content/getAudiobook'](fu.slug),
            ]
            video.value = [
              ...video.value,
              ...ctx.root.$store.getters['content/getVideo'](fu.slug),
            ]
            await ctx.root.$store.dispatch('content/setLessonPlans', fu.slug)
            lessonPlans.value = [
              ...lessonPlans.value,
              ...ctx.root.$store.getters['content/getLessonPlans'](fu.slug),
            ]
          }),
        )
      }

      //add unit
      crumbs.value.push({
        type: 'search',
        name: title.value,
        slug: `?unit=${unitSlug.value}&type=${ctx.root.$options.router.history.current.query.type}`,
      })

      loading.value = true
    })

    const onSelectedTagUpdate = st => {
      selectedTags.value = st.selectedTags
      selectedTagsMobile.value = st.selectedTags
    }

    const onSelectedTagMobileUpdate = st => {
      //saved temporary until apply filters
      selectedTagsMobile.value = st.selectedTagsMobile
    }

    const onSelectedThemesUpdate = sth => {
      selectedThemes.value = sth.selectedThemes
    }

    return {
      unitSlug,
      tags,
      text,
      video,
      audiobook,
      filteredLessonPlans,
      onChangeTab,
      isTeacher,
      loading,
      title,
      description,
      onSelectedTagUpdate,
      onSelectedTagMobileUpdate,
      onSelectedThemesUpdate,
      crumbs,
      units,
      isMobileFilterOpen,
      onMobileFilterBtn,
      onMobileFilterClose,
      onFiltersApply,
      selectedThemes,
      selectedTags,
      selectedTagsMobile,
    }
  },
}
</script>

<style lang="scss">
.c-search {
  &--echo-1-identity {
    .c-unit__header {
      background-color: $unit-identity-bg;
    }
  }
  &--echo-2-english-around-the-world {
    .c-unit__header {
      background-color: $unit-english-bg;
    }
  }
  &--echo-3-citizenship {
    .c-unit__header {
      background-color: $unit-citizenship-bg;
    }
  }
  &--echo-4-indigenous-peoples {
    .c-unit__header {
      background-color: $unit-indigenous-bg;
    }
  }
  &--echo-my-grammar {
    .c-unit__header {
      background-color: $unit-grammar-bg;
    }
  }
}

.c-search__breadcrumbs {
  padding: 0.5rem 1rem;
  @include bp-down($large) {
    display: none;
  }

  .c-breadcrumbs__home {
    text-decoration: none;
    color: $color-black;
    .c-breadcrumbs__home {
      fill: none;
      width: 0;
    }
    &:before {
      content: 'Home';
      vertical-align: super;
    }
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}

.c-search__unit-description {
  max-width: $search-items-max-width;
  margin: 1rem auto 0;
}

.c-tab__panel {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 2rem 0;

  @include bp-down(large) {
    padding-top: 0;
  }

  &#text {
    .c-search-tile {
      .c-search-tile__icon--text {
        display: block;
      }
    }
  }
  &#video {
    .c-search-tile {
      .c-search-tile__icon--video {
        display: block;
      }
    }
  }
  &#audio {
    .c-search-tile {
      .c-search-tile__icon--audio {
        display: block;
      }
    }
  }
  &#lesson-plans {
    .c-search-tile {
      .c-search-tile__icon--lp {
        display: block;
      }
    }
  }
}

.c-search__empty {
  font-size: 1rem;
  font-weight: 700;
  margin: 3rem 1rem;
}

.c-search__wrapper {
  display: flex;
  max-width: $large;
  margin: 0 auto;
}

.c-search__filters {
  @include bp-down($large) {
    display: none;
  }
  width: 240px;
  min-width: 240px;
  border-right: 1px solid $color-divider;
}

.c-search__tabs {
  width: 100%;
  margin: 0 auto;
  padding: 0 0.5rem;
  @include bp-down($small) {
    margin: 0 1rem;
  }
}

.c-tabs__list-wrapper {
  max-width: $search-items-max-width;
  background: none;
  border: none;
  margin: 0 auto;
  @include bp-down($large) {
    padding-top: 0.5rem;
  }
}

.c-tabs__btn {
  width: 100%;
  margin: 0;
  background: none;
  border-radius: 0;
  border: 1px solid $color-divider !important;
  border-top-right-radius: 15px;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  padding: 0.75rem 1.5rem;
  background: $color-white;
  &--active {
    background: $tab-active-bg !important;
    color: $color-black !important;
  }

  @include bp-down($small) {
    padding: 0.75rem 1px 0.75rem 16px;
    overflow: hidden;
  }

  &::before {
    display: none;
  }
}

.c-tabs {
  padding: 0 !important;
  padding-left: 10px !important;

  @include bp-down($small) {
    width: 100%;
  }
}

.c-tabs__panels-wrapper,
.c-tab__panel {
  max-width: $search-items-max-width;
  background: none !important;
  margin: 0 auto;
}

.c-tabs__item {
  padding: 0 !important;
  margin-left: -10px;
  flex-grow: 1;

  @for $i from 1 through 4 {
    &:nth-of-type(#{$i}) {
      z-index: 4- $i;
    }
  }

  @include bp-down($small) {
    min-width: auto;
  }

  &:before {
    display: none;
  }
}

.c-search__unit-nav {
  padding-left: 10px;

  &-title {
    font-size: 1.5rem;
    font-weight: normal;
  }

  &-side {
    padding: 0 !important;
  }

  &-item {
    font-size: 1rem;
    line-height: 2.5rem;
    margin-bottom: 10px;
    cursor: pointer;
    &:before {
      content: '';
      width: 10px;
      height: 2.5rem;
      padding: 0;
      margin: 0 -16px;
      opacity: 0.1;
      transition: opacity 500ms;
    }
    &.active {
      font-weight: 700;
      &:before {
        opacity: 1;
      }
    }

    &:hover:not(.active) {
      &:before {
        opacity: 0.6;
      }
    }
    &.unit-nav-item--echo-1-identity {
      &:before {
        background-color: $unit-identity-bg;
      }
    }

    &.unit-nav-item--echo-2-english-around-the-world {
      &:before {
        background-color: $unit-english-bg;
      }
    }

    &.unit-nav-item--echo-3-citizenship {
      &:before {
        background-color: $unit-citizenship-bg;
      }
    }
    &.unit-nav-item--echo-4-indigenous-peoples {
      &:before {
        background-color: $unit-indigenous-bg;
      }
    }
    &.unit-nav-item--echo-my-grammar {
      &:before {
        background-color: $unit-grammar-bg;
      }
    }
  }
}
.c-search__unit-header {
  margin: 0 -0.5rem 1rem;
  @include bp-down($small) {
    margin: 0 -1.5rem 1rem;
  }

  img {
    max-width: 100%;
  }
  .c-unit__unit-title h2 {
    font-size: 1.5rem;
    margin: 0.25rem 0;
  }
}

.c-search__btn-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1.25rem 0;
}

.c-search__btn-lp-filter {
  @include btnReset();

  display: flex;
  align-items: center;
  font-size: 0.875rem;

  @include bp(large) {
    display: none;
  }

  &:hover {
    z-index: 3;
  }

  svg {
    width: 1.25rem;
    margin-right: 0.4rem;
  }
}
</style>
