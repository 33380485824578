<template>
  <div class="c-filters-dropdown">
    <div class="c-filters-dropdown__btn-wrapper">
      <FakeCheckbox v-show="fakeSelected" :selected="fakeSelected" class="c-filters-dropdown__fake" />
      <VButton class="c-filters-dropdown__btn" @click.native="toggleShow">
        {{ name }}
        <span class="c-filters-dropdown__icon" :class="{ 'c-filters-dropdown__icon--rotate': show }">
          ^
        </span>
      </VButton>
    </div>
    <div v-show="show" class="c-filters-dropdown__filters-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import VButton from '@forlagshuset/v-button'
import FakeCheckbox from './FakeCheckbox'

export default {
  name: 'FiltersDropdown',

  components: {
    VButton,
    FakeCheckbox
  },

  data() {
    return {
      show: false,
      fakeSelected: false
    }
  },

  methods: {
    toggleShow() {
      this.show = !this.show
    }
  },

  props: {
    name: String
  }
}
</script>

<style lang="scss">
.c-filters-dropdown__fake {
  position: absolute;
  left: 0;
  opacity: 0.5;
  top: 50%;
  margin-top: 0.3rem;
  transform: translateY(-50%);
}

.c-filters-dropdown {
  margin: 0.5rem 0;
  padding-left: 3.2rem;
}
.c-filters-dropdown__btn {
  font-size: 1rem;
  background: none!important;
  border: none!important;
  position: relative;
  padding: 0 1rem 0 0;
}

.c-filters-dropdown__btn-wrapper {
  position: relative;
}

.c-filters-dropdown__icon {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: 50% 35%;
  transform: rotate(90deg);

  &--rotate {
    transform: rotate(180deg);
  }
}
</style>