<template>
  <div class="c-fake-checkbox">
    <span v-show="selected" class="c-fake-checkbox__selected" />
  </div>
</template>

<script>
export default {
  props: {
    selected: Boolean
  }
}
</script>

<style lang="scss">
.c-fake-checkbox {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border: 1px solid $color-black;
}

.c-fake-checkbox__selected {
  &::after {
    content: "\2713";
    font-size: 1.3rem;
    line-height: 1;
  }
}
</style>