<template>
  <div class="c-filters-input">
    <label class="c-filters-input__wrapper u-px">
      <input class="c-filters-input__input" type="checkbox" :checked="selected" @change="$emit('filter-selected', $event)" />
      <FakeCheckbox :selected="selected" />
      <div class="c-filters-input__text">
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<script>
import FakeCheckbox from './FakeCheckbox'

export default {
  name: 'FiltersInput',

  props: {
    selected: Boolean,
  },

  components: {
    FakeCheckbox
  }
}
</script>

<style lang="scss">
.c-filters-input {
  margin-top: 1rem;
}

.c-filters-input__input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:focus + .c-fake-checkbox {
    outline: 2px solid;
  }
}

.c-filters-input__wrapper {
  display: flex;
  cursor: pointer;
  padding-left: 0;
}

.c-filters-input__text {
  width: calc(100% - 2rem);
  font-size: 1rem;
  margin-left: 1rem;
}
</style>